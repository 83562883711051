var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.isDialog?'q-dialog':'div',{tag:"component",attrs:{"id":"customer-trans-list"},on:{"hide":_vm.cancel},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c('div',[_c('ListTable',{ref:"tableRef",attrs:{"slim":"","columns":_vm.columns,"fetchAsync":_vm.fetchCustomerTranses,"dataName":"customertranses","selectable":_vm.selectable,"search":"customer.name,orderNo,balance,credit,pension"},scopedSlots:_vm._u([{key:"head(balance)",fn:function(){return [_c('fa-icon',{staticClass:"text-primary mr-50",attrs:{"icon":"dollar-sign"}}),_vm._v(_vm._s(_vm.capitalize(_vm.$t('mlm.balance'))))]},proxy:true},{key:"head(credit)",fn:function(){return [_c('fa-icon',{staticClass:"text-warning mr-50",attrs:{"icon":"coins"}}),_vm._v(" "+_vm._s(_vm.capitalize(_vm.$t('mlm.credit')))+" ")]},proxy:true},{key:"head(pension)",fn:function(){return [_c('fa-icon',{staticClass:"text-success mr-50",attrs:{"icon":"piggy-bank"}}),_vm._v(" "+_vm._s(_vm.capitalize(_vm.$t('mlm.pension')))+" ")]},proxy:true},{key:"cell(customer.name)",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('q-btn',{staticClass:"w-100 rounded-lg",attrs:{"flat":"","padding":"0.25rem 0.50rem"},on:{"click":function($event){_vm.customerViewRef.open(item.customer._id).then(function (v){ return v?_vm.tableRef.refetchTable():''; })}}},[_c('div',{staticClass:"flex-grow-1 d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"flex gap-2 align-items-center flex-nowrap"},[_c('Avatar',{attrs:{"url":item.customer.files,"defaultIcon":"user"}}),_c('div',{staticClass:"d-flex flex-column align-items-start"},[_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.customer.name)+" "),_c('q-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.customer.customerNo),expression:"item.customer.customerNo",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(function (){ return _vm.Toast.fire({icon:'success', title:_vm.capitalize(_vm.$t('form.copied'))}); }),expression:"()=>Toast.fire({icon:'success', title:capitalize($t('form.copied'))})",arg:"success"}],staticClass:"ml-25",attrs:{"flat":"","padding":"none"}},[_c('fa-icon',{staticClass:"text-primary",attrs:{"icon":['far','clone']}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","offset":[10, 10]}},[_vm._v(_vm._s(item.customer.customerNo))])],1)],1),(item.customer.grade)?_c('q-chip',{attrs:{"size":"sm","color":'light-'+item.customer.grade.color}},[_c('q-avatar',{attrs:{"color":(item.customer.mlmSeq===1||item.customer.mlmSeq%2===0)?'primary':'danger',"text-color":"white"}},[_vm._v(_vm._s(item.customer.level))]),_c('span',[_vm._v(_vm._s(item.customer.grade.names.find(function (n){ return n.locale === _vm.$i18n.locale; }).name))])],1):_vm._e()],1)],1),_c('fa-icon',{staticClass:"text-primary ml-1",attrs:{"icon":['far','chevron-right']}})],1)]):_vm._e()]}},{key:"cell(orderNo)",fn:function(ref){
var item = ref.item;
return [_c('q-btn',{staticClass:"w-100 rounded-lg",attrs:{"flat":"","padding":"0.25rem 0.50rem"},on:{"click":function($event){$event.stopPropagation();return _vm.saleViewRef.open(item.sale)}}},[_c('div',{staticClass:"flex-grow-1 d-flex justify-content-between align-items-center"},[_c('div',[(item.contributor)?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.contributor.name))]):_vm._e(),_c('br'),(item.orderNo)?_c('q-chip',{attrs:{"size":"sm"}},[_vm._v(_vm._s(item.orderNo))]):_vm._e()],1),_c('fa-icon',{staticClass:"text-primary ml-1",attrs:{"icon":['far','chevron-right']}})],1)])]}},{key:"cell(createdAt)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("date")(value))),_c('br'),_vm._v(" "+_vm._s(_vm._f("time")(value))+" ")]}},{key:"cell(balance)",fn:function(ref){
var value = ref.value;
return [(value)?_c('q-chip',{attrs:{"dense":"","color":"light-primary"}},[_c('q-avatar',{attrs:{"color":"primary","text-color":"white"}},[_c('fa-icon',{attrs:{"icon":"dollar-sign"}})],1),_c('span',{staticClass:"text-nowrap font-weight-bold mx-25",class:{'text-danger':value.c<0}},[_vm._v(" "+_vm._s(value.c<0?'-':'')+" "+_vm._s(_vm._f("price")(Math.abs(value.c||0)))+" ")])],1):_vm._e(),(value)?_c('div',[_vm._v(_vm._s(value.b)+" "),_c('fa-icon',{class:value.c<0?'text-danger':'text-primary',attrs:{"icon":"caret-right"}}),_vm._v(" "+_vm._s(value.a))],1):_vm._e()]}},{key:"cell(credit)",fn:function(ref){
var value = ref.value;
return [(value)?_c('q-chip',{attrs:{"dense":"","color":"light-warning"}},[_c('q-avatar',{attrs:{"color":"warning","text-color":"white"}},[_c('fa-icon',{attrs:{"icon":"coins"}})],1),_c('span',{staticClass:"text-nowrap font-weight-bold mx-25",class:{'text-danger':value.c<0}},[_vm._v(" "+_vm._s(value.c<0?'-':'')+" "+_vm._s(_vm._f("price")(Math.abs(value.c||0)))+" ")])],1):_vm._e(),(value)?_c('div',[_vm._v(_vm._s(value.b)+" "),_c('fa-icon',{class:value.c<0?'text-danger':'text-primary',attrs:{"icon":"caret-right"}}),_vm._v(" "+_vm._s(value.a))],1):_vm._e()]}},{key:"cell(pension)",fn:function(ref){
var value = ref.value;
return [(value)?_c('q-chip',{attrs:{"dense":"","color":"light-success"}},[_c('q-avatar',{attrs:{"color":"success","text-color":"white"}},[_c('fa-icon',{attrs:{"icon":"piggy-bank"}})],1),_c('span',{staticClass:"text-nowrap font-weight-bold mx-25",class:{'text-danger':value.c<0}},[_vm._v(" "+_vm._s(value.c<0?'-':'')+" "+_vm._s(_vm._f("price")(Math.abs(value.c||0)))+" ")])],1):_vm._e(),(value)?_c('div',[_vm._v(_vm._s(value.b)+" "),_c('fa-icon',{class:value.c<0?'text-danger':'text-primary',attrs:{"icon":"caret-right"}}),_vm._v(" "+_vm._s(value.a))],1):_vm._e()]}},{key:"cell(rewardChild)",fn:function(ref){
var value = ref.value;
return [(value)?_c('q-chip',{attrs:{"dense":""}},[_c('q-avatar',{attrs:{"color":"primary","text-color":"white"}},[_c('fa-icon',{attrs:{"icon":"dollar-sign"}})],1),_c('span',{staticClass:"text-nowrap font-weight-bold mx-25",class:{'text-danger':value<0}},[_vm._v(" "+_vm._s(value<0?'-':'')+" "+_vm._s(_vm._f("price")(Math.abs(value||0)))+" ")])],1):_vm._e()]}},{key:"cell(rewardOrg)",fn:function(ref){
var value = ref.value;
return [(value)?_c('q-chip',{attrs:{"dense":""}},[_c('q-avatar',{attrs:{"color":"primary","text-color":"white"}},[_c('fa-icon',{attrs:{"icon":"dollar-sign"}})],1),_c('span',{staticClass:"text-nowrap font-weight-bold mx-25",class:{'text-danger':value<0}},[_vm._v(" "+_vm._s(value<0?'-':'')+" "+_vm._s(_vm._f("price")(Math.abs(value||0)))+" ")])],1):_vm._e()]}},{key:"cell(rewardGroup)",fn:function(ref){
var value = ref.value;
return [(value)?_c('q-chip',{attrs:{"dense":""}},[_c('q-avatar',{attrs:{"color":"primary","text-color":"white"}},[_c('fa-icon',{attrs:{"icon":"dollar-sign"}})],1),_c('span',{staticClass:"text-nowrap font-weight-bold mx-25",class:{'text-danger':value<0}},[_vm._v(" "+_vm._s(value<0?'-':'')+" "+_vm._s(_vm._f("price")(Math.abs(value||0)))+" ")])],1):_vm._e()]}},{key:"cell(team1)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-content-around align-items-start"},[(item.rewardTeam1)?_c('q-chip',{attrs:{"dense":""}},[_c('q-avatar',{attrs:{"color":"primary","text-color":"white"}},[_c('fa-icon',{attrs:{"icon":"dollar-sign"}})],1),_c('span',{staticClass:"text-nowrap font-weight-bold mx-25",class:{'text-danger':_vm.value<0}},[_vm._v(" "+_vm._s(item.rewardTeam1<0?'-':'')+" "+_vm._s(_vm._f("price")(Math.abs(item.rewardTeam1||0)))+" ")])],1):_vm._e(),(item.creditTeam1)?_c('q-chip',{attrs:{"dense":""}},[_c('q-avatar',{attrs:{"color":"orange","text-color":"white"}},[_c('fa-icon',{attrs:{"icon":"coins"}})],1),_c('span',{staticClass:"text-nowrap font-weight-bold mx-25",class:{'text-danger':_vm.value<0}},[_vm._v(" "+_vm._s(item.creditTeam1<0?'-':'')+" "+_vm._s(_vm._f("price")(Math.abs(item.creditTeam1||0)))+" ")])],1):_vm._e()],1)]}},{key:"cell(team2)",fn:function(ref){
var item = ref.item;
return [(item.rewardTeam2)?_c('q-chip',{attrs:{"dense":""}},[_c('q-avatar',{attrs:{"color":"primary","text-color":"white"}},[_c('fa-icon',{attrs:{"icon":"dollar-sign"}})],1),_c('span',{staticClass:"text-nowrap font-weight-bold mx-25",class:{'text-danger':_vm.value<0}},[_vm._v(" "+_vm._s(item.rewardTeam2<0?'-':'')+" "+_vm._s(_vm._f("price")(Math.abs(item.rewardTeam2||0)))+" ")])],1):_vm._e(),(item.creditTeam2)?_c('q-chip',{attrs:{"dense":""}},[_c('q-avatar',{attrs:{"color":"orange","text-color":"white"}},[_c('fa-icon',{attrs:{"icon":"coins"}})],1),_c('span',{staticClass:"text-nowrap font-weight-bold mx-25",class:{'text-danger':_vm.value<0}},[_vm._v(" "+_vm._s(item.creditTeam2<0?'-':'')+" "+_vm._s(_vm._f("price")(Math.abs(item.creditTeam2||0)))+" ")])],1):_vm._e()]}}])}),_c('CustomerView',{ref:"customerViewRef"}),_c('SaleView',{ref:"saleViewRef"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }