import { ref } from '@vue/composition-api'
import store from '@/store'
import customerTransStoreModule from './customerTransStoreModule'
if (!store.hasModule('customerTrans')) store.registerModule('customerTrans', customerTransStoreModule)

export default function useCustomerTransList(props, emit) {
  const loading = ref(false)

  return {
    loading
  }
}
