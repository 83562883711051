import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomerTranses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/customer-trans', { params: queryParams })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchCustomerTrans(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/customerTrans/${id}`)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  }
}
