<template>
  <component :is="isDialog?'q-dialog':'div'" id="customer-trans-list" v-model="isDialogOpen" @hide="cancel">
    <div>
      <ListTable ref="tableRef" slim :columns="columns" :fetchAsync="fetchCustomerTranses" dataName="customertranses" :selectable="selectable" search="customer.name,orderNo,balance,credit,pension">
        <template #head(balance)>
          <fa-icon icon="dollar-sign" class="text-primary mr-50"/>{{capitalize($t('mlm.balance'))}}</template>
        <template #head(credit)>
          <fa-icon icon="coins" class="text-warning mr-50"/> {{capitalize($t('mlm.credit'))}}
        </template>
        <template #head(pension)>
          <fa-icon icon="piggy-bank" class="text-success mr-50"/> {{capitalize($t('mlm.pension'))}}
        </template>
        <!-- Customer -->
        <template #cell(customer.name)="{item}">
          <q-btn flat padding="0.25rem 0.50rem" v-if="item.customer" @click="customerViewRef.open(item.customer._id).then(v=>v?tableRef.refetchTable():'')" class="w-100 rounded-lg">
            <div class="flex-grow-1 d-flex justify-content-between align-items-center">
              <div class="flex gap-2 align-items-center flex-nowrap">
                <Avatar :url="item.customer.files" defaultIcon="user"/>
                <div class="d-flex flex-column align-items-start">
                  <div class="text-nowrap">
                    {{item.customer.name}}
                    <q-btn flat padding="none" v-clipboard:copy="item.customer.customerNo" v-clipboard:success="()=>Toast.fire({icon:'success', title:capitalize($t('form.copied'))})" class="ml-25">
                      <fa-icon :icon="['far','clone']" class="text-primary"/>
                      <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">{{item.customer.customerNo}}</q-tooltip>
                    </q-btn>
                  </div>
                  <q-chip v-if="item.customer.grade" size="sm" :color="'light-'+item.customer.grade.color">
                    <q-avatar :color="(item.customer.mlmSeq===1||item.customer.mlmSeq%2===0)?'primary':'danger'" text-color="white">{{item.customer.level}}</q-avatar>
                    <span>{{item.customer.grade.names.find(n=>n.locale === $i18n.locale).name}}</span>
                  </q-chip>
                </div>
              </div>
              <fa-icon :icon="['far','chevron-right']" class="text-primary ml-1"/>
            </div>
          </q-btn>
        </template>

        <!-- OrderNo -->
        <template #cell(orderNo)="{ item }">
          <q-btn flat padding="0.25rem 0.50rem" @click.stop="saleViewRef.open(item.sale)" class="w-100 rounded-lg">
            <div class="flex-grow-1 d-flex justify-content-between align-items-center">
              <div>
                <span v-if="item.contributor" class="text-nowrap">{{item.contributor.name}}</span><br>
                <q-chip v-if="item.orderNo" size="sm">{{item.orderNo}}</q-chip>            
              </div>
              <fa-icon :icon="['far','chevron-right']" class="text-primary ml-1"/>
            </div>
          </q-btn>
        </template>

        <!-- Created -->
        <template #cell(createdAt)="{ value }">
          {{value|date}}<br>
          {{value|time}}
        </template>
        
        <!-- Balance -->
        <template #cell(balance)="{ value }">
          <q-chip v-if="value" dense color="light-primary">
            <q-avatar color="primary" text-color="white"><fa-icon icon="dollar-sign" /></q-avatar>
            <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value.c<0}">
              {{value.c<0?'-':''}} {{Math.abs(value.c||0) | price}}
            </span>
          </q-chip>
          <div v-if="value">{{value.b}} <fa-icon icon="caret-right" :class="value.c<0?'text-danger':'text-primary'"/> {{value.a}}</div>
        </template>
        <!-- Credit -->
        <template #cell(credit)="{ value }">
          <q-chip v-if="value" dense color="light-warning">
            <q-avatar color="warning" text-color="white"><fa-icon icon="coins" /></q-avatar>
            <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value.c<0}">
              {{value.c<0?'-':''}} {{Math.abs(value.c||0) | price}}
            </span>
          </q-chip>
          <div v-if="value">{{value.b}} <fa-icon icon="caret-right" :class="value.c<0?'text-danger':'text-primary'"/> {{value.a}}</div>
        </template>
        <!-- Pension -->
        <template #cell(pension)="{ value }">
          <q-chip v-if="value" dense color="light-success">
            <q-avatar color="success" text-color="white"><fa-icon icon="piggy-bank" /></q-avatar>
            <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value.c<0}">
              {{value.c<0?'-':''}} {{Math.abs(value.c||0) | price}}
            </span>
          </q-chip>
          <div v-if="value">{{value.b}} <fa-icon icon="caret-right" :class="value.c<0?'text-danger':'text-primary'"/> {{value.a}}</div>
        </template>
        <!-- child -->
        <template #cell(rewardChild)="{ value }">
          <q-chip v-if="value" dense>
            <q-avatar color="primary" text-color="white"><fa-icon icon="dollar-sign" /></q-avatar>
            <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
              {{value<0?'-':''}} {{Math.abs(value||0) | price}}
            </span>
          </q-chip>
        </template>
        <!-- org -->
        <template #cell(rewardOrg)="{ value }">
          <q-chip v-if="value" dense>
            <q-avatar color="primary" text-color="white"><fa-icon icon="dollar-sign" /></q-avatar>
            <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
              {{value<0?'-':''}} {{Math.abs(value||0) | price}}
            </span>
          </q-chip>
        </template>
        <!-- Group -->
        <template #cell(rewardGroup)="{ value }">
          <q-chip v-if="value" dense>
            <q-avatar color="primary" text-color="white"><fa-icon icon="dollar-sign" /></q-avatar>
            <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
              {{value<0?'-':''}} {{Math.abs(value||0) | price}}
            </span>
          </q-chip>
        </template>
        <!-- Team 1 -->
        <template #cell(team1)="{ item }">
          <div class="d-flex flex-column justify-content-around align-items-start">
            <q-chip v-if="item.rewardTeam1" dense>
              <q-avatar color="primary" text-color="white"><fa-icon icon="dollar-sign" /></q-avatar>
              <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
                {{item.rewardTeam1<0?'-':''}} {{Math.abs(item.rewardTeam1||0) | price}}
              </span>
            </q-chip>
            <q-chip v-if="item.creditTeam1" dense>
              <q-avatar color="orange" text-color="white"><fa-icon icon="coins" /></q-avatar>
              <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
                {{item.creditTeam1<0?'-':''}} {{Math.abs(item.creditTeam1||0) | price}}
              </span>
            </q-chip>
          </div>
        </template>
        <!-- Team 2 -->
        <template #cell(team2)="{ item }">
          <q-chip v-if="item.rewardTeam2" dense>
            <q-avatar color="primary" text-color="white"><fa-icon icon="dollar-sign" /></q-avatar>
            <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
              {{item.rewardTeam2<0?'-':''}} {{Math.abs(item.rewardTeam2||0) | price}}
            </span>
          </q-chip>
          <q-chip v-if="item.creditTeam2" dense>
            <q-avatar color="orange" text-color="white"><fa-icon icon="coins" /></q-avatar>
            <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
              {{item.creditTeam2<0?'-':''}} {{Math.abs(item.creditTeam2||0) | price}}
            </span>
          </q-chip>
        </template>
      </ListTable>

      <CustomerView ref="customerViewRef"/>
      <SaleView ref="saleViewRef"/>
    </div>
  </component>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { price, capitalize, date, time, i18n } from '@core/utils/filter'
import store from '@/store'
import useCustomerTrans from './useCustomerTrans' // Needed for store
import CustomerView from '../customer/CustomerView'
import SaleView from '@/views/supply-chain/sale/SaleView'
import Avatar from '@core/components/Avatar'
import ListTable from '@core/components/ListTable'

export default {
  name: 'customer-trans-list',
  components: {
    Avatar, ListTable,
    SaleView,
    CustomerView
  },
  props:{
    isDialog: Boolean,
    showIcon: Boolean,
    selectable: Boolean,
    filters: {
      type: Object,
      default: ()=>{}
    },
    sort: String
  },
  filters: {
    capitalize, price, date, time
  },  
  setup() {
    // Ref
    const customerViewRef = ref(null)
    const saleViewRef = ref(null)

    // Table
    const tableRef = ref(null)
    const columns = ref([
      {key:'customer.name', label:i18n('member.customer._'), sortable: true, searchLabel:i18n('form.name') },
      {key:'orderNo', label: i18n('form.order_number'), sortable: true},
      {key:'createdAt', isDateRange: true, sortable: true},
      {key:'balance', sortable: true},
      {key:'credit', sortable: true},
      {key:'pension', sortable: true},
      {key:'rewardChild',label:i18n('mlm.child')},
      {key:'rewardOrg',label:i18n('mlm.org')}, 
      {key:'rewardGroup',label:i18n('mlm.group')}, 
      {key:'team1',label:i18n('mlm.team')+'1'},
      {key:'team2',label:i18n('mlm.team')+'2'}
    ])

    // Action
    const fetchCustomerTranses = params => store.dispatch('customerTrans/fetchCustomerTranses', params)

    // Dialog view
    const isDialogOpen = ref(false)
    
    let cancelAction = () => isDialogOpen.value = false
    const cancel = ()=>cancelAction()
    const selectOne = ()=>new Promise(res => {
      // 隐藏column
      columns.value = columns.value.filter(c=>c.key!=='actions')
      isDialogOpen.value = true
      cancelAction = ()=>{
        isDialogOpen.value = false
        res(false)
      }
      watch(tableRef, ref=>ref?.clickRow().then(val=>{
        isDialogOpen.value = false
        res(val)
      }))
    })

    return {
      capitalize,
      // Dialog View
      isDialogOpen,
      cancel,
      selectOne,

       // Table
      columns,
      tableRef,

      // Action
      fetchCustomerTranses,

      // Refs
      customerViewRef,
      saleViewRef
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/base/bootstrap-extended/_variables.scss';
#customer-trans-list {
  .row {
    flex-wrap: nowrap;
  }
}
</style>